import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25153a00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-block" }
const _hoisted_2 = { class: "panel-block-t" }
const _hoisted_3 = { class: "panel-block-c" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.blockData.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockData.children, (it, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: idx,
          onClick: ($event: any) => (_ctx.fGoPage(it))
        }, [
          _createElementVNode("div", {
            class: "iconbox",
            style: _normalizeStyle({ background: it.bgColor.replace(', 1)', ',0.3)') })
          }, [
            _createVNode(_component_svg_icon, {
              class: "icon",
              style: _normalizeStyle({ color: it.bgColor }),
              "icon-class": it.icon
            }, null, 8, ["style", "icon-class"])
          ], 4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(it.name), 1)
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}