
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() blockData: any;

  fGoPage(ployload: any) {
    this.$store.dispatch(
      "project/getButtonPermissions",
      this.$store.state.project.projectinfo.projectId
    );
    switch (ployload.name) {
      case "安全巡检":
        this.$router.push("/app/anquanxunjian");
        break;
      case "人员信息管理":
        this.$router.push("/app/personrecord");
        break;
      case "设备管理":
        this.$router.push("/app/devicemanage");
        break;
      case "人员出入":
        this.$router.push("/app/personaccess");
        break;
      case "考勤汇总":
        this.$router.push("/app/duty");
        break;
      case "人员档案":
        this.$router.push("/app/personrecord");
        break;
      case "项目详情":
        this.$store.state.project.projectinfo.id =
          this.$store.state.project.projectinfo.projectId;
        this.$store.state.project.projectinfo.name =
          this.$store.state.project.projectinfo.projectName;
        this.$store.commit(
          "project/SET_projectinfoDetail",
          this.$store.state.project.projectinfo
        );
        this.$router.push({
          path: "/app/projectInfo",
          query: { id: this.$store.state.project.projectinfo.projectId },
        });
        break;
      case "安全隐患检查":
        this.$router.push("/app/safecheck");
        break;
      case "访客登记":
        this.$router.push("/app/visitor");
        break;
      case "访客审批":
        this.$router.push("/app/visitorApprove");
        break;
      case "环境监测":
        this.$router.push("/app/environmentaltest");
        break;
      case "可视化监控":
        this.$router.push("/app/visualmonitor");
        break;
      case "车辆出入":
        this.$router.push("/app/carmonitor");
        break;
      case "随手拍":
        this.$router.push("/app/clap");
        break;
      case "隐患随手拍":
        this.$router.push("/app/clap");
        break;
      case "质量巡检":
        this.$router.push("/app/qualitycheck");
        break;
      case "视频巡检":
        this.$router.push("/app/videoInspection");
        break;
      case "督导检查":
        this.$router.push("/app/leadercheck");
        break;
      case "移动巡检":
        this.$router.push("/app/inspection");
        break;
      case "课程培训":
        this.$router.push("/app/courselearn");
        break;
      case "在线测试":
        this.$router.push("/app/onlineexam");
        break;
      case "车辆档案":
        this.$router.push("/app/cararchives");
        break;
      case "区域管理":
        this.$router.push("/app/areamanage");
        break;
      case "纪检安全":
        this.$router.push("/app/disciplinesafe");
        break;
      case "消杀记录":
        this.$router.push("/app/killrecord");
        break;
      case "疫情防控":
        this.$router.push("/app/epidemic");
        break;
      case "访客记录":
        this.$router.push("/app/visitorrecord");
        break;
      case "进度管理":
        this.$router.push("/app/progressplan");
        break;
      case "通知管理":
        this.$router.push("/mynotice");
        break;
    }
  }
}
